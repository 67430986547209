export default {
    page_not_found: 'Page Not Found',
    msg_page_not_found: 'The page "{0}" could not be found.',
    login_msg: 'Please, click on the login button.',
    login_via_peakwork: 'To login...',
    main_navigation: {
        cet_main: 'Upload file',
        cet_analysis: 'Analysis',
    },
    buttons: {
        labels: {
            logout: 'Logout',
            show_feedback: 'Show Feedback'
        }
    },
    home: {
        title: 'Home Content Evaluation Tool'
    },
    errors: {
        title: 'Error',
        upload: {
            unmarshal: 'Invalid file format',
            size: 'File size exceeds limit',
            general: 'Unassigned exception',
            extension: 'The file format is not allowed'
        }
    },
    success: {
        title: 'Success',
        uploaded: 'The file has been successfully uploaded.'
    },
    info: {
        analysis_started: 'The analysis has been started in the background.'
    },
    analysis: {
        title: 'Analysis',
        errors: {
            title: 'The following errors have been encountered during the analysis',
            getresults: 'Getting cEDF analysis results',
            notavailableTitle: 'The analysis is not available.',
            noneTitle: 'No errors have been found during the analysis',
            // Example of the errorKey that we can receive from the backend!!!
            keys: {
                CONTENT_ROOT_NULL: 'Missing ContentRoot element.',
                BASIC_DATA_NULL: 'Missing BasicData element.',
                ADDRESS_NULL: 'Missing Address element.',
                GEO_INFO_NULL: 'Missing GeoInfos element.',
                GEO_CODE_NULL: 'Missing GeoCode element.',
                LAT_BELOW_NEGATIVE_90: 'Invalid Latitude < -90',
                LAT_ABOVE_90: 'Invalid Latitude > 90',
                LONG_BELOW_NEGATIVE_180: 'Invalid Longitude < -180',
                LONG_ABOVE_180: 'Invalid Longitude > 180',
                NOT_ALLOWED_0_0_COORDINATES: 'Coordinates (0,0) are not allowed.',
                COUNTRY_CODE_NULL: 'Missing Country element.',
                TOUR_OPERATOR_NULL: 'Missing TourOperatorCode attribute.',
                NAME_NULL: 'Missing Name element.',
                PRODUCT_CODE_NULL: 'Missing Code attribute.',
                COUNTRY_CODE_NOT_ISO_3166_ALPHA2: 'The provided country code is not ISO-3166 Alpha-2',
                DESCRIPTION_COUNTRY_CODE_NOT_ISO_3166_ALPHA2: 'Description with non ISO-3166 Alpha-2 country provided',
                UNLINKED_FEATURES: 'The cEDF contains Features that are not linked by FeatureLinks. These Features have the following IDs:',
                DEAD_FEATURE_LINKS_HOTEL: 'The cEDF contains Hotel FeatureLinks that point to nonexistent Features. The nonexistent Features have the following IDs:',
                DEAD_FEATURE_LINKS_ROOM: 'The cEDF contains Room FeatureLinks that point to nonexistent Features. The nonexistent Features have the following IDs:',
                UNLINKED_IMAGES: 'The cEDF contains Images that are not linked by MultimediaLinks. These Images have the following IDs:',
                DEAD_IMAGE_LINKS_HOTEL: 'The cEDF contains Hotel MultimediaLinks that point to nonexistent Images. The nonexistent Images have the following IDs:',
                DEAD_IMAGE_LINKS_ROOM: 'The cEDF contains Room MultimediaLinks that point to nonexistent Images. The nonexistent Images have the following IDs:',
                MULTI_MEDIA_LINK_TYPE_NOT_IMAGE: 'The cEDF contains MultimediaLinks that do not have the Type set to "Image". The MultimediaLinks missing this Type have the following IDs:',
                MULTI_MEDIA_IDS_FOR_IMAGES_NOT_UNIQUE: 'The cEDF contains MultiMedia images with IDs that are not unique.',
                XSD: 'The cEDF violates the Content EDF XSD with the following errors:',
                COUNTRY_CODE_COORDINATES: 'The provided country code does not match the one resolved from the provided coordinates. The provided country code is mentioned first, the country code resolved from the coordinates is mentioned second:',
                LOCALE_HOTEL_DESCRIPTION: 'The cEDF contains Descriptions with locales for which no Descriptions with the category "Hotel" are provided. The locales for which a Description with the category "Hotel" is missing are:',
                LOCALE_CATEGORY_DESCRIPTION_HOTEL_UNIQUE: 'The cEDF contains hotel Descriptions where locale and category are not unique. The duplicate locales and categories are:',
                LOCALE_CATEGORY_DESCRIPTION_ROOM_UNIQUE: 'The cEDF contains room Descriptions where locale and category are not unique. The duplicate room code, locales and categories are:',
                UNREGISTERED_CONTENT_TOCODE: 'The provided supplier code in the attribute TourOperatorCode was not registered as content supplier in the Peakwork Network (ICM). The provided supplier code was: ',
                UNREGISTERED_CONTENT_SUPPLIER_CODE: 'The provided supplier code during the upload was not registered as content supplier in the Peakwork Network (ICM). The provided supplier code was: ',
                FEATURE_IDS_NOT_UNIQUE: 'The cEDF contains Features with IDs that are not unique. The non-unique Feature IDs are: ',
                INVALID_SERVICE_TYPE: 'The cEDF contains one or more unsupported service types. The services have the codes: ',
                UNREADABLE_ZIP_FILE: 'The uploaded ZIP file is unreadable.',
                PROCESSING_ERROR: 'The uploaded file cannot be processed.',
                BROKEN_ZIP_ENTRY: 'The file from the uploaded ZIP is unreadable: ',
                FILE_EXCEEDS_MAXIMAL_SIZE: 'The uploaded file exceeds maximal allowed size: ',
                SUPPLIER_CODE_DIFFERS_FROM_GIVEN_CODE_ZIP: 'The provided supplier code in the attribute TourOperatorCode differs from the content supplier provided during the upload. The supplier code from the the attribute TourOperatorCode and the provided supplier code were: ',
                SUPPLIER_CODE_DIFFERS_FROM_GIVEN_CODE_CSV: 'The provided value for the supplier code differs from the content supplier provided during the upload. The supplier code from the csv column and the upload were: ',
                DUPLICATE_PRODUCT_CODES: 'The provided ProductCodes are not unique. The duplicated ProductCodes are the following:',
                BROKEN_CSV_LINE: 'The line from the csv is unreadable.',
                CSV_LINE_IS_EMPTY: 'The line from the csv is empty.',
                CSV_LINE_WITH_INVALID_COLUMN_NUMBER: 'The line from the csv contains invalid number of columns. The number of columns in this line is:',
                LATITUDE_NOT_DOUBLE: 'The provided value for the latitude was not a numeral value, but: ',
                LONGITUDE_NOT_DOUBLE: 'The provided value for the longitude was not a numeral value, but: ',
                OBJECT_SEGMENTATION_METATYPE_NOT_INT: 'The provided value for the object segmentation type was not an integer, but: ',
                REF_ID_PARSING_ERROR: 'The provided value for the reference IDs could not be parsed, provided value is: ',
                UNREADABLE_CSV_FILE: 'The uploaded CSV is unreadable.',
                PRODUCT_CODE_MALFORMED: 'Code attribute is malformed. Check for leading or trailing white spaces.',
				UNKNOWN_ERROR: 'The tool seems to be having temporary problems, please upload your file again. If the error persists, please contact Peakwork with a screenshot of this page.',
                GIATAID_MALFORMED: 'The provided giata-id is not an integer number.'
            }
        }
    },
    feedback: {
        overview: {
            warning: {
                pending: 'Currently in PENDING state!'
            },
            button: {
                refreshpage: 'Refresh Page'
            }
        },
        single: {
            title: 'Product Analysis Details',
            overview: {
                title: 'Overview',
                details: {
                    title: 'Details',
                    id: 'Upload ID',
                    filename: 'Filename',
                    line: 'Line',
                    type: 'Type',
                    createdAt: 'Start Of Evaluation',
                    success: 'Analysis Result',
                    status: 'Status Analysis',
                    batchId: 'Parent Batch ID',
                    parenttype: 'Parent Batch Type'
                }
            }
        },
        batch: {
            header: {
                title: '{batchtype} Analysis details',
                overview: 'Overview',
                table: 'Content Table'
            },
            overview: {
                batchdetails: {
                    title: 'Batch Details',
                    csv: {
                        total: 'Total Rows',
                        failed: 'Total Failed Rows',
                        success: 'Total Successful Rows'
                    },
                    zip: {
                        total: 'Total Files',
                        failed: 'Total Failed Files',
                        success: 'Total Successful Files'
                    }
                },
                batcherrors: {
                    title: 'Batch Errors'
                },
                producterrors: {
                    title: 'Product Errors (ErrorMessage | Total of affected files)'
                }
            },
            table: {
                title: '',
                filter: {
                    title: 'Filter',
                    errorkey: {
                        label: 'Error',
                        placeholder: 'Pick An Error'
                    }
                }
            }
        },
        errors: {
            keys: {
                CONTENT_ROOT_NULL: 'Missing ContentRoot element.',
                BASIC_DATA_NULL: 'Missing BasicData element.',
                ADDRESS_NULL: 'Missing Address element.',
                GEO_INFO_NULL: 'Missing GeoInfos element.',
                GEO_CODE_NULL: 'Missing GeoCode element.',
                LAT_BELOW_NEGATIVE_90: 'Invalid Latitude < -90.',
                LAT_ABOVE_90: 'Invalid Latitude > 90.',
                LONG_BELOW_NEGATIVE_180: 'Invalid Longitude < -180.',
                LONG_ABOVE_180: 'Invalid Longitude > 180.',
                NOT_ALLOWED_0_0_COORDINATES: 'Coordinates (0,0) are not allowed.',
                COUNTRY_CODE_NULL: 'Missing Country element.',
                TOUR_OPERATOR_NULL: 'Missing TourOperatorCode attribute.',
                NAME_NULL: 'Missing Name element.',
                PRODUCT_CODE_NULL: 'Missing Code attribute.',
                COUNTRY_CODE_NOT_ISO_3166_ALPHA2: 'The provided country code is not ISO-3166 Alpha-2.',
                DESCRIPTION_COUNTRY_CODE_NOT_ISO_3166_ALPHA2: 'Description with non ISO-3166 Alpha-2 country provided.',
                UNLINKED_FEATURES: 'The cEDF contains Features that are not linked by FeatureLinks.',
                DEAD_FEATURE_LINKS_HOTEL: 'The cEDF contains Hotel FeatureLinks that point to nonexistent Features.',
                DEAD_FEATURE_LINKS_ROOM: 'The cEDF contains Room FeatureLinks that point to nonexistent Features.',
                UNLINKED_IMAGES: 'The cEDF contains Images that are not linked by MultimediaLinks.',
                DEAD_IMAGE_LINKS_HOTEL: 'The cEDF contains Hotel MultimediaLinks that point to nonexistent Images.',
                DEAD_IMAGE_LINKS_ROOM: 'The cEDF contains Room MultimediaLinks that point to nonexistent Images.',
                MULTI_MEDIA_LINK_TYPE_NOT_IMAGE: 'The cEDF contains MultimediaLinks that do not have the Type set to "Image".',
                MULTI_MEDIA_IDS_FOR_IMAGES_NOT_UNIQUE: 'The cEDF contains MultiMedia images with IDs that are not unique.',
                XSD: 'The cEDF violates the Content EDF XSD',
                COUNTRY_CODE_COORDINATES: 'The provided country code does not match the one resolved from the provided coordinates.',
                LOCALE_HOTEL_DESCRIPTION: 'The cEDF contains Descriptions with locales for which no Descriptions with the category "Hotel" are provided.',
                LOCALE_CATEGORY_DESCRIPTION_HOTEL_UNIQUE: 'The cEDF contains hotel Descriptions where locale and category are not unique.',
                LOCALE_CATEGORY_DESCRIPTION_ROOM_UNIQUE: 'The cEDF contains room Descriptions where locale and category are not unique.',
                UNREGISTERED_CONTENT_TOCODE: 'The provided supplier code in the attribute TourOperatorCode was not registered as a content supplier within the ICM.',
                UNREGISTERED_CONTENT_SUPPLIER_CODE: 'The provided supplier code during the upload was not registered as content supplier in the Peakwork Network (ICM).',
                FEATURE_IDS_NOT_UNIQUE: 'The cEDF contains Features with IDs that are not unique.',
                INVALID_SERVICE_TYPE: 'The cEDF contains one or more unsupported service types.',
                UNREADABLE_ZIP_FILE: 'The uploaded ZIP file is unreadable.',
                PROCESSING_ERROR: 'The uploaded file cannot be processed.',
                BROKEN_ZIP_ENTRY: 'The file from the uploaded ZIP is unreadable.',
                FILE_EXCEEDS_MAXIMAL_SIZE: 'The uploaded file exceeds maximal allowed size.',
                SUPPLIER_CODE_DIFFERS_FROM_GIVEN_CODE_ZIP: 'The provided supplier code in the attribute TourOperatorCode differs from the content supplier provided during the upload.',
                SUPPLIER_CODE_DIFFERS_FROM_GIVEN_CODE_CSV: 'The provided value for the supplier code differs from the content supplier provided during the upload.',
                DUPLICATE_PRODUCT_CODES: 'The provided ProductCodes are not unique.',
                BROKEN_CSV_LINE: 'The line from the csv is unreadable.',
                CSV_LINE_IS_EMPTY: 'The line from the csv is empty.',
                CSV_LINE_WITH_INVALID_COLUMN_NUMBER: 'The line from the csv contains invalid number of columns.',
                LATITUDE_NOT_DOUBLE: 'The provided value for the latitude was not a numeral value.',
                LONGITUDE_NOT_DOUBLE: 'The provided value for the longitude was not a numeral value.',
                OBJECT_SEGMENTATION_METATYPE_NOT_INT: 'The provided value for the object segmentation type was not an integer.',
                REF_ID_PARSING_ERROR: 'The provided value for the reference IDs could not be parsed.',
                UNREADABLE_CSV_FILE: 'The uploaded CSV is unreadable.',
                PRODUCT_CODE_MALFORMED: 'Code attribute is malformed. Check for leading or trailing white spaces.',
                UNKNOWN_ERROR: 'The tool seems to be having temporary problems, please upload your file again. If the error persists, please contact Peakwork with a screenshot of this page.',
                GIATAID_MALFORMED: 'The provided giata-id is not an integer number.'
            }
        }
    },
    table: {
        headers: {
            upload_id: 'Upload ID',
            uploaded_at: 'Uploaded At',
            type: 'Type',
            status: 'Status Analysis',
            success: 'Analysis Result',
            progress: 'Analysis Progress',
            actions: 'Analysis Details',
            filename: 'Filename',
            line: 'Line'
        }
    },
    upload: {
        title: 'File Upload',
        warning: {
            suppliercode: 'For ZIP and CSV Upload it is mandatory to enter a Supplier Code'
        },
        input: {
            supplier: 'Enter Supplier Code'
        }
    }
};
