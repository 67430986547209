import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messagesEnUs from './messages.en_US';
import moment from 'moment';
import config from '@/config';

Vue.use(VueI18n);
moment.locale(['en']);

const MOMENT_TRANSLATION_MAPPING = {
    en_US: 'en',
};

const i18n = new VueI18n({
    messages: {
        en_US: messagesEnUs,
    },
    locale: config.defaultLanguage,
    fallbackLang: config.defaultLanguage,
});

const i18nMixin = {
    computed: {
        language() {
            return config.defaultLanguage || 'en_US';
        },
    },
    watch: {
        language(language, oldLanguage) {
            if (language !== oldLanguage) {
                this.$i18n.locale = language;
                moment.locale(MOMENT_TRANSLATION_MAPPING[language]);
            }
        },
    },
    created() {
        this.$i18n.locale = this.language;
        moment.locale(MOMENT_TRANSLATION_MAPPING[this.language]);
    },
};

export default i18n;
export {
    i18n,
    i18nMixin,
};
