import moduleFn from 'pw-auth/lib/store/user';
import _merge from 'lodash/merge';
import config from '@/config';

/**
 * @var {
 *     namespaced: true,
 *     persist: {Object},
 *     state: {Object},
 *     mutations: {Object},
 *     actions: {Object},
 *     getters: {Object}
 * }
 */
const userModule = moduleFn({
    key: config.localStorage.key /* name of the key for the local storage */,
    lifetime: config.localStorage.lifetime /* lifetime for the local storage */,
    defaultLanguage: 'en_US' /* default language */,
    initialState: {}, /* define initial values for custom keys */
});

const mutations = {};
const actions = {};
const getters = {
    accessToken: function accessToken(state) {
        try {
            return state.env.user.token.access_token;
        } catch (err) {}
        return null;
    },
};

export default {
    namespaced: true,
    state: userModule.state,
    mutations: _merge(userModule.mutations, mutations),
    actions: _merge(userModule.actions, actions),
    getters: _merge(userModule.getters, getters),
};
