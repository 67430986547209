import Vue from 'vue';
import Router from 'vue-router';
import routesItems from './routes';
import store from '@/vuex';
import routerGuard from 'pw-auth/lib/router';

Vue.use(Router);

function generateRoutesFromMenu(menu = [], routes = []) {
    for (let i = 0, l = menu.length; i < l; i++) {
        const item = menu[i];
        if (item.path) {
            routes.push(item);
        }

        if (!item.component) {
            generateRoutesFromMenu(item.children, routes);
        }
    }

    return routes;
}

const router = new Router({
    linkActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: generateRoutesFromMenu(routesItems),
});

router.beforeEach((to, from, next) => {
    routerGuard(router.app, store, to, next, true);
});

export default router;
