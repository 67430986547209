import axios from 'axios';
import config from '@/config/index';

let bearerToken = null;

function initAxiosRequest(options) {
    const request = {};
    request.http = axios.create({
        baseURL: config.backendUrl,
        timeout: options.timeout || 15000,
        headers: getHeaders(options),
    });
    request.http.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response?.data?.errorKey === 'EMPTY_TOKEN_EXCEPTION') {
            window.location.reload();
        }
        return Promise.reject(error);
    });
    return request;
}

function getHeaders(options) {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (options.headers) {
        for (const hdr in options.headers) {
            headers[hdr] = options.headers[hdr];
        }
    }
    if (bearerToken) {
        headers.Authorization = 'Bearer ' + bearerToken;
    }
    return headers;
}

export default {
    methods: {
        createAxiosRequest(options) {
            bearerToken = this.$store.getters['userModule/accessToken'];
            return initAxiosRequest(options);
        },
    },
};
