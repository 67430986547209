import Vue from 'vue';
import App from './App';
import router from './router';
import store from './vuex';
import Resource from 'vue-resource';
import configs from './config';
import PeakworkAuthPlugin from 'pw-auth';
import PeakworkICMPlugin from 'pw-icm';
import { i18n, i18nMixin } from './i18n';
import toastrMixin from './mixins/toastr';
import loadingLayer from './mixins/loadingLayer';
import axiosRequests from './mixins/axiosRequests';
import BootstrapVue from 'bootstrap-vue';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Resource);
Vue.use(PeakworkAuthPlugin, {
    clientId: configs.authParams.clientId,
    clientSecret: configs.authParams.clientSecret,
    loginApiUrl: configs.authParams.loginApiUrl,
    apiUrl: configs.authParams.apiUrl,
    accountsUrl: configs.authParams.accountsUrl,
});

Vue.use(PeakworkICMPlugin, {
    apiUrl: configs.icmParams.apiUrl,
    timeouts: {
        get: 5000,
        search: 0,
    },
});

new Vue({
    el: '#app',
    mixins: [
        i18nMixin,
        toastrMixin,
        loadingLayer,
        axiosRequests,
    ],
    i18n,
    router,
    store,
    render: h => h(App),
});
