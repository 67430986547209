export default {
    methods: {
        /**
         * Get the toastr element from the app container (currently the first child).
         * @see https://www.npmjs.com/package/vue-toastr
         * @returns {Object}
         */
        getToastr() {
            return this.$children[0].$refs.toastr;
        },

        /**
         * Notifies a message.
         * @param {String} msg
         * @param {String} title
         * @param {String} type
         */
        notify(msg, title = undefined, type = 'success') {
            switch (type) {
                case 'error':
                    this.notifyError(msg, title);
                    break;
                case 'warning':
                    this.notifyWarning(msg, title);
                    break;
                case 'info':
                    this.notifyInfo(msg, title);
                    break;
                default:
                    this.notifySuccess(msg, title);
            }
        },

        /**
         * Notifies a success message in the toastr container.
         * @param {String} msg
         * @param {String} title
         */
        notifySuccess(msg, title = undefined) {
            this.getToastr().s(msg, title);
        },

        /**
         * Notifies an error message in the toastr container.
         * @param {String} msg
         * @param {String} title
         */
        notifyError(msg, title = undefined) {
            this.getToastr().e(msg, title);
        },

        /**
         * Notifies an critical error message in the toastr container without timeout
         * User needs to confirm by click it away
         * @param {String} msg
         * @param {String} title
         */
        notifyCritical(msg, title, timeout) {
            this.notifyError({
                msg,
                title,
                position: 'toast-top-full-width',
                timeout: timeout || 0,
            });
        },

        /**
         * Notifies a warning message in the toastr container.
         * @param {String} msg
         * @param {String} title
         */
        notifyWarning(msg, title = undefined) {
            this.getToastr().w(msg, title);
        },

        /**
         * Notifies an info message in the toastr container.
         * @param {String} msg
         * @param {String} title
         */
        notifyInfo(msg, title = undefined) {
            this.getToastr().i(msg, title);
        },
    },
};
