// Load dev environment
// via https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const devConfigVarsString = process.env.VUE_APP_CONFIG_VARS;
if (devConfigVarsString) {
    // eslint-disable-next-line no-console
    console.log(
        'Reading dev env from process.env.VUE_APP_CONFIG_VARS:',
        devConfigVarsString,
    );
    const devConfigVars = JSON.parse(devConfigVarsString);
    window.__pwenv = { ...devConfigVars };
}

export default {
    authParams: {
        clientId: window.__pwenv.SSO.CLIENT_ID,
        clientSecret: window.__pwenv.SSO.CLIENT_SECRET,
        loginApiUrl: window.__pwenv.SSO.SERVICE_URL,
        apiUrl: window.__pwenv.SSO.SERVICE_URL,
        accountsUrl: window.__pwenv.SSO.ACCOUNTS_URL,
        timeouts: {
            token: 60000,
            refreshToken: 60000,
            userInformation: 60000,
        },
    },
    icmParams: {
        apiUrl: window.__pwenv.ICM.API,
    },
    localStorage: {
        key: 'cet-web-app-storage',
        lifetime: 7 * 24 * 60 * 60 * 1e3, // one week
    },
    defaultLanguage: 'en_US',
    // proxy: window.__pwenv.PROXY,
    backendUrl: window.__pwenv.BACKEND_PROTOCOL + '://' + window.__pwenv.BACKEND_HOST + ':' + window.__pwenv.BACKEND_PORT,
    backendWsUrl: window.__pwenv.BACKEND_WS_URL + ':' + window.__pwenv.BACKEND_WS_PORT,
};
