import { lazyLoading } from '../utils/helpers';

let routes = [];

routes = routes.concat([
    {
        name: 'home',
        path: '/',
        showInMenu: true,
        component: lazyLoading('home'),
        meta: {
            label: 'main_navigation.cet_main',
            secured: true,
        },
    },
    {
        name: 'analysis',
        path: '/analysis',
        showInMenu: true,
        component: lazyLoading('analysis'),
        meta: {
            label: 'main_navigation.cet_analysis',
            secured: true,
        },
    },
    {
        name: 'feedbacksingle',
        path: '/feedback/single/:id',
        component: lazyLoading('feedback', 'Single'),
        meta: {
            secured: true,
        },
    },
    {
        name: 'feedbackbatchentry',
        path: '/feedback/batch/entry/:id/batch/:batchid',
        component: lazyLoading('feedback', 'BatchEntry'),
        meta: {
            secured: true,
        },
    },
    {
        name: 'feedbackbatch',
        path: '/feedback/batch/:type(csv|zip)/:id',
        component: lazyLoading('feedback', 'Batch'),
        meta: {
            secured: true,
        },
    },
]);

routes = routes.concat([{
    path: '*',
    component: lazyLoading('404'),
    meta: {
        label: 'page_not_found',
        secured: true,
    },
}]);

routes = routes.concat(require('pw-auth/lib/router/login').default);
routes = routes.concat(require('pw-auth/lib/router/logout').default);
routes = routes.concat(require('pw-auth/lib/router/token').default);
routes = routes.concat(require('pw-auth/lib/router/accessDenied').default);

export default routes;
