import Vue from 'vue';
import Vuex from 'vuex';

/**
 * modules
 */
import userModule from './modules/user';
import routesModule from './modules/routes';

/**
 * modules
 */

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        userModule,
        routesModule,
    },
});
