<template>
  <div id="app">
    <router-view></router-view>
    <vue-toastr ref="toastr"></vue-toastr>
    <pw-loading-layer :startLayerTimeout="0" ref="loadingLayer"></pw-loading-layer>
  </div>
</template>

<script>
import VueToastr from 'vue-toastr';
import PwLoadingLayer from 'pw-loading-layer';

export default {
    name: 'app',
    components: {
        VueToastr,
        PwLoadingLayer,
    },
};
</script>

<style lang="scss">
  @import './assets/scss/app.scss';

  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    background-color:pwColor('pewter', 4);
    padding: 0;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #6a6c6f;
    height: 100%;
  }
  a {
    color: pwColor('cerulean');
  }
  #app {
    height: 100%;
  }
</style>
