import routes from '@/router/routes';

const state = {
    routes,
};

const mutations = {};

const actions = {};

const getters = {
    routes: state => state.routes,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
